<template>
  <div @keydown.esc="hideComparison" class="justify-center items-center">
    <div
      v-if="comparisonShown"
      class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 bg-gray-700 opacity-90 flex flex-col items-center justify-center"
    >
      <div
        class="group flex flex-row text-center text-white font-semibold text-xl hover:underline hover:cursor-pointer"
        @click="comparisonShown = !comparisonShown"
      >
        <!-- Σύγκριση καλύψεων -->
        <div class="mb-4 flex flex-row">
          <p class="px-4">Κλείσιμο σύγκρισης</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="15" y1="9" x2="9" y2="15"></line>
            <line x1="9" y1="9" x2="15" y2="15"></line>
          </svg>
        </div>
      </div>
      <div class="container mt-2">
        <policy-comparator
          :policies="pivotData"
          @requestClose="hideComparison"
        />
      </div>
    </div>

    <k-dialog
      v-if="overlayHidden == false"
      :title="'Αποθήκευση προσφορών'"
      @close="closeOverlay"
    >
      <div class="flex flex-col">
        <div
          v-if="showSavePicker"
          class="justify-center items-center text-center"
        >
          <div>
            Επιλέξτε ένα όνομα για την αποθήκευση
            <div class="mt-2">
              <k-input v-model="offerName" />
            </div>
          </div>

          <div class="mt-8">
            Επιλέξτε τις προσφορές που θέλετε να αποθηκευτούν
            <table class="mt-2 table-fixed">
              <thead>
                <tr>
                  <!-- <th>ID</th> -->
                  <th class="w-[200px]">Policy</th>
                  <th class="w-[150px]">Cost</th>
                  <th class="w-[150px]">Επιλογή</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(quote, index) in quotes"
                  v-bind:key="quote.id"
                  class="py-2"
                  v-bind:style="{
                    backgroundColor: colors[index % 2],
                  }"
                >
                  <!-- //v1.0.3.10	Προσθήκη background color στο πλαίσιο όπου ο χρήστης αποθηκεύει την προσφορά -->
                  <!-- <td class="text-md">#{{quote.QuoteID}}</td> -->
                  <td>{{ quote.policyName }}</td>
                  <td>€ {{ quote.price.toLocaleString("de-DE") }}</td>
                  <td>
                    <k-checkbox @change="savePolicyChanged($event, quote)" />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="justify-center items-center text-center">
          <div>Η αποθήκευση ολοκληρώθηκε!</div>
        </div>
        <k-dialog-actions-bar class="mt-8">
          <k-button :theme-color="'primary'" @click="closeOverlayAndSave"
            >Αποθήκευση επιλεγμένων</k-button
          >
        </k-dialog-actions-bar>
      </div>
    </k-dialog>

    <!-- component -->
    <div
      v-if="quotes.length > 0"
      class="justify-center items-center content-center justify-content-center"
    >
      <!--------------------//v1.0.4.2  Responsive το quote card σε μικρότερες οθόνες------------------>
      <div class="para flex flex-row flex-0 overflow-x-auto justify-center">
        <!--------------------//v1.0.4.2  Responsive το quote card σε μικρότερες οθόνες------------------>
        <template v-for="quote in quotes" v-bind:key="quote.id">
          <quote-card
            :quote="quote"
            @newPolicyRequested="switchToNewPolicyRequest(quote)"
          />
        </template>
      </div>

      <div class="mt-2">
        <a
          href="/travel/save-offer"
          class="text-sm text-red-600"
          :class="{
            'line-through': false,
            'hover:underline focus:text-red-800': true,
          }"
          @click.prevent="showOverlay(quote)"
        >
          Αποθήκευση προσφορών
        </a>
      </div>

      <div class="mt-2">
        <a
          href="/compare"
          class="text-sm text-red-600"
          :class="{
            'line-through': false,
            'hover:underline focus:text-red-800': true,
          }"
          @click.prevent="comparisonShown = !comparisonShown"
        >
          Σύγκριση καλύψεων
        </a>
      </div>
    </div>
    <div v-else>
      <span class="text-xl"
        >Παρακαλώ περιμένετε, γίνεται υπολογισμός της προσφοράς.</span
      >

      <div>
        <svg
          role="status"
          class="inline w-10 h-10 mt-8 text-gray-200 animate-spin dark:text-gray-600"
          viewBox="0 0 100 101"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
            fill="currentColor"
          />
          <path
            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
            fill="#1C64F2"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<!--------------------//v1.0.4.2  Responsive το quote card σε μικρότερες οθόνες------------------>
<style scoped>
@media only screen and (max-width: 1909px) {
  .para {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    margin-left: 13rem; /* 160px */
    margin-right: 13rem;
  }
}

@media only screen and (min-width: 1910px) {
  .para {
    display: flex;
  }
}
</style>
<!--------------------//v1.0.4.2  Responsive το quote card σε μικρότερες οθόνες------------------>

<script>
import apiClient from "@/apiClient";
import { mapActions } from "vuex";

import { dummyQuotes } from "./dummyQuotes";
import { Dialog } from "@progress/kendo-vue-dialogs";

import { Button } from "@progress/kendo-vue-buttons";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { Input } from "@progress/kendo-vue-inputs";

import { DialogActionsBar } from "@progress/kendo-vue-dialogs";
import PolicyComparator from "./PolicyComparator.vue";

import QuoteCard from "./NewQuoteRequest/QuoteCard.vue";
export default {
  components: {
    "k-dialog": Dialog,
    "k-button": Button,
    "k-checkbox": Checkbox,
    "k-input": Input,
    "k-dialog-actions-bar": DialogActionsBar,
    PolicyComparator,
    QuoteCard,
  },

  data: function () {
    return {
      comparisonShown: false,
      offerName: null,
      selectedPoliciesForSaving: [],
      overlayHidden: true,
      request: null,
      quotes: [],
      pivotData: null,
      colors: ["#FFFFFF", `#C9C7C6`],
    };
  },

  computed: {
    formValueKeys() {
      return Object.keys(this.kendoForm.values);
    },
  },

  inject: ["kendoForm"],

  props: ["lastChangedStepNumber", "activeStepNumber", "myStepNumber"],

  watch: {
    activeStepNumber: function (val) {
      if (val == this.myStepNumber) {
        this.stepActivated();
      }
    },
  },

  mounted() {
    // this.stepActivated()
  },

  methods: {
    hideComparison() {
      if (this.comparisonShown) this.comparisonShown = false;
    },

    savePolicyChanged(event, shit) {
      const quoteId = shit.QuoteID;
      const enabled = event.value;

      const quoteInList = this.selectedPoliciesForSaving.includes(quoteId);

      if (enabled == true && quoteInList == false)
        this.selectedPoliciesForSaving.push(quoteId);

      if (enabled == false && quoteInList == true) {
        const arrayIndex = this.selectedPoliciesForSaving.indexOf(quoteId);

        if (false == (arrayIndex == null))
          this.selectedPoliciesForSaving.splice(arrayIndex, 1);
      }
    },

    showOverlay(quote) {
      this.overlayHidden = false;
      this.showSavePicker = true;
      this.selectedPoliciesForSaving = [];
    },

    closeOverlay() {
      this.overlayHidden = true;
    },

    closeOverlayAndSave() {
      if (this.selectedPoliciesForSaving.length > 0 == false) return;

      if (this.offerName?.length > 0 == false) return;

      this.saveOffer(this.selectedPoliciesForSaving.join(","), this.offerName);

      this.closeOverlay();

      this.$swal
        .fire({
          title: "Επιτυχία",
          text: `Τα επιλεγμένα στοιχεία αποθηκέυτηκαν επιτυχώς`,
          icon: "success",
        })
        .then((response) => {
          this.$router.push("/travel/saved-offers");
        });
    },

    ...mapActions(["setCurrentQuoteForPolicyCreation"]),

    async stepActivated() {
      console.log(
        `step four activated?! last changed step: ${this.lastChangedStepNumber}`
      );

      if (this.lastChangedStepNumber < this.myStepNumber) {
        this.stepReset();
      }

      if (this.quotes.length == 0) {
        // return
        const requestPayload = this.generateRequest();
        requestPayload.NN_Agent_Code =
          this.$store.state.userAgentCode ?? this.$store.state.user;

        const quotesResponse = await apiClient.getQuotes(requestPayload);

        // const quotesResponse = dummyQuotes;

        const quotesResponsePolicyNames = Object.keys(quotesResponse);

        let invalidQuoteNames = [];
        quotesResponsePolicyNames.forEach((name) => {
          const quote = quotesResponse[name];

          if (quote.QuoteID == 0) invalidQuoteNames.push(name);
        });

        invalidQuoteNames.forEach((name) => {
          delete quotesResponse[name];
        });

        const quotes = quotesResponse;
        // let quotes = dummyQuotes

        this.pivotData = this.createPivotData(quotes);

        const quoteNames = Object.keys(quotes);

        let outputQuotes = [];

        for (let quoteIndex = 0; quoteIndex < quoteNames.length; quoteIndex++) {
          const currentQuote = quotes[quoteNames[quoteIndex]];

          const outputQuote = {
            ...currentQuote,
            policyName: quoteNames[quoteIndex],
          };

          outputQuotes.push(outputQuote);
        }

        this.quotes = outputQuotes;
        // console.log(quotes)
      }
    },

    stepReset() {
      this.quotes = [];
    },

    createPivotData(response) {
      const horizontalHeaders = Object.keys(response);
      const verticalHeaders = response[horizontalHeaders[0]].coverages
        .sort((x) => x.visOrder)
        .map((x) => x.cover_description);

      const valuesByRowCol = [];

      for (let row = 1; row <= verticalHeaders.length; row++) {
        let rowValues = [];

        for (let col = 1; col <= horizontalHeaders.length; col++) {
          // console.log(`attacking row ${row} col ${col}`);

          let targetPackage = horizontalHeaders[col - 1];

          // console.log(`target package: ${targetPackage}`);

          let coverage = response[targetPackage].coverages[row - 1];

          if (coverage == null) {
            // console.log(`coverage with index ${row - 1} does not exist ?!`);
          }

          let cellValues = {
            sums_insured: coverage.sums_insured,
            excess: coverage.excess,
          };

          rowValues.push(cellValues);
        }

        valuesByRowCol.push(rowValues);
      }

      return {
        horizontalHeaders,
        verticalHeaders,
        valuesByRowCol,
      };
    },

    generateRequest() {
      const values = this.kendoForm.values;

      const dateFormatter = (date) =>
        date.toLocaleDateString("el-GR", {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        });

      return {
        token: values.apiToken,
        platform: values.insuranceCompany,
        travel_type: values.travelCategory,
        travel_category: values.travelType,
        residence: values.residenceCountry.label,
        destination: values.destination.value,
        //v1.0.1.6  Προσθήκη insured_category key στην function generateRequest()
        insured_category: values.insuredCategory,
        //v1.0.1.6	Προσθήκη insured_category key στην function generateRequest()
        start_date: dateFormatter(values.travelStartDate),
        end_date: dateFormatter(values.travelEndDate),

        additional_coverages: this.getAdditionalCoverages(),
        travellers: this.getTravellers(),
      };
    },

    getAdditionalCoverages() {
      const allAdditionalCoverages = Object.keys(this.kendoForm.values)
        .filter((x) => x.startsWith("extra_coverage_boolean."))
        .map((x) => ({
          coverageId: x.split(".")[1],
          enabled: this.kendoForm.values[x],
        }));

      const enabledAdditionalCoverages = allAdditionalCoverages.filter(
        (x) => x.enabled == true
      );

      const additionalSelectionCoverages = Object.keys(this.kendoForm.values)
        .filter((x) => x.startsWith("extra_coverage_selection."))
        .filter((x) => this.kendoForm.values[x].coverage_id > 0)
        .map((x) => ({
          coverageId: this.kendoForm.values[x].coverage_id,
          enabled: true,
        }));
      // console.log(additionalSelectionCoverages)

      let output = [];

      enabledAdditionalCoverages.forEach((item) =>
        output.unshift({ coverage_id: parseInt(item.coverageId) })
      );
      additionalSelectionCoverages.forEach((item) =>
        output.unshift({ coverage_id: parseInt(item.coverageId) })
      );

      return output;
    },

    getTravellers() {
      const allAgeBands = Object.keys(this.kendoForm.values)
        .filter((x) => x.startsWith("age_band"))
        .map((x) => ({
          ageBandId: x.split(".")[1],
          travellerCount: parseInt(this.kendoForm.values[x]),
        }));

      const activeAgeBands = allAgeBands.filter((x) => x.travellerCount > 0);

      let output = [];

      activeAgeBands.forEach((item) => {
        const dummyArray = Array(item.travellerCount).fill({
          AgeBandId: item.ageBandId,
        });

        dummyArray.forEach((element) => output.push(element));
      });

      return output;
    },

    async saveOffer(quoteString, name) {
      const token = this.kendoForm.values.apiToken;
      const nnAgentCode =
        this.$store.state.userAgentCode ?? this.$store.state.user;

      await apiClient.saveTravelOffer(token, nnAgentCode, quoteString, name);
    },

    switchToNewPolicyRequest(quote) {
      const ageBandFieldNames = Object.keys(this.kendoForm.values).filter((x) =>
        x.startsWith("age_band")
      );

      const ageBandNameDeconstructor = (name) => {
        const matches = name.match(/age_bands.(\d+).(\d+)-(\d+)/);

        const ageBandId = matches[1];
        const ageBandMinAge = matches[2];
        const ageBandMaxAge = matches[3];

        return { id: ageBandId, minAge: ageBandMinAge, maxAge: ageBandMaxAge };
      };

      let ageBandsInfo = [];
      ageBandFieldNames.forEach((ageBandFieldName) => {
        const ageBandPeople = Number(this.kendoForm.values[ageBandFieldName]);

        if (ageBandPeople > 0 == false) return;

        const ageBand = ageBandNameDeconstructor(ageBandFieldName);

        ageBandsInfo.push({ ageBand: ageBand, persons: ageBandPeople });
      });

      const policyDetails = {
        quoteId: quote.QuoteID,
        packageName: quote.policyName,
        price: quote.price.toFixed(2),
        residence: this.kendoForm.values.residenceCountry.label,
        destination: this.kendoForm.values.destination.value,
        fromDate: this.kendoForm.values.travelStartDate.toLocaleDateString(
          "el-GR",
          { month: "2-digit", day: "2-digit", year: "numeric" }
        ),
        toDate: this.kendoForm.values.travelEndDate.toLocaleDateString(
          "el-GR",
          { month: "2-digit", day: "2-digit", year: "numeric" }
        ),
        personsPerAgeBand: ageBandsInfo,
      };

      // console.log(policyDetails)

      this.setCurrentQuoteForPolicyCreation(policyDetails);

      this.$router.replace({
        name: "newtravelpolicyrequestview",
      });
    },
  },
};
</script>
