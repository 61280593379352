import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueSweetalert2 from 'vue-sweetalert2'

import axios from 'axios'
import VueAxios from 'vue-axios'

import 'sweetalert2/dist/sweetalert2.min.css';
import './assets/tailwind.css'

import VueStepWizard from 'vue-step-wizard'
import 'vue-step-wizard/dist/vue-step-wizard.css'


const app = createApp(App);


fetch('/cfg.json')
    .then( response => response.json() )
    .then( liveConfigData => {

        if (process.env.VUE_APP_API_BASE_URL)
            liveConfigData.API_BASE_URL = process.env.VUE_APP_API_BASE_URL;

        if (process.env.VUE_APP_SHOW_DEV_FEATURES)
            liveConfigData.SHOW_DEV_FEATURES = (process.env.VUE_APP_SHOW_DEV_FEATURES === 'true');
       
        if (process.env.VUE_APP_SHOW_DEV_FEATURES)
            liveConfigData.SHOW_DEV_FEATURES = (process.env.SHOW_DEV_FEATURES === 'true');

        app
            .provide('$appConfig', liveConfigData)
            .use(store)
            .use(router)
            .use(VueSweetalert2)
            .use(VueAxios, axios)
            .use(VueStepWizard)
            .mount('#app')
    
    })