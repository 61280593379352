import { createStore } from 'vuex'
import router from '@/router';

export default createStore({
  state: {
    user: null,
    userFullName: null,
    userAgentCode: null,
    token: null,
    timeout: {
      running: false,
      seconds: null,
      defaultSeconds: null
    },
    currentPageTitle: null,

    currentQuoteForPolicyCreation: null,

  },
  getters: {
    getToken(state) {
      return state.token;
    },

    getTimeoutSeconds(state) {
      return state.timeout.seconds
    },

    getCurrentQuote(state) {
      return state.currentQuoteForPolicyCreation
    }
  },
  mutations: {
    loggedIn(state, {user, userFullName, userAgentCode, passwordChangeRequired, token}) {
      state.user = user;
      state.token = token;
      state.userFullName = userFullName;
      state.userAgentCode = userAgentCode;
      state.passwordChangeRequired = passwordChangeRequired;
    },

    loggedOut(state) {
      state.user = null;
      state.token = null;
      state.userFullName = null;
      state.userAgentCode = null;
      state.timeout = {
          running: false,
          seconds: null,
          defaultSeconds: null
      }
    },

    timeoutTimerTicked(state) {
      if (state.timeout.running == false)
        return
      
      state.timeout.seconds = (state.timeout.seconds ?? 0) - 1
    },

    timeoutTimerReset(state, seconds) {
      state.timeout.seconds = seconds
    },

    timeoutTimerStarted(state) {
      state.timeout.running = true
    },

    timeoutTimerStopped(state) {
      state.timeout.running = false
    },

    timeoutTimerRestarted(state) {
      state.timeout.running = true
      state.timeout.seconds = state.timeout.defaultSeconds
    },

    switchedPageTitle(state, title) {
      state.currentPageTitle = title;
    },

    clearedPageTitle(state) {
      state.currentPageTitle = null;
    },

    currentQuoteSet(state, quote) {
      state.currentQuoteForPolicyCreation = quote
    },

    currentQuoteCleared(state) {
      state.currentQuoteForPolicyCreation = null;
    }


  },
  actions: {
    clearPageTitle(context) {
      context.commit('clearedPageTitle');
    },

    switchPageTitle(context, title) {
      context.commit('switchedPageTitle', title);
    },

    storageSetToken(context, payload ) {
      const { token, permaStorage } = payload

      if (permaStorage)
        localStorage.setItem('KUA_API_TOKEN', token)
      else
        sessionStorage.setItem('KUA_API_TOKEN', token)
    },

    storageClearToken() {
      sessionStorage.removeItem('KUA_API_TOKEN')
      localStorage.removeItem('KUA_API_TOKEN')
    },

    logOut(context) {
      context.dispatch('timeoutTimerStop')
      context.dispatch('storageClearToken')
      context.commit('loggedOut');
      router.push('/login')
    },

    logIn(context, {user, userFullName, userAgentCode, passwordChangeRequired, token, permaStorage, timeoutSeconds}) {
      
      context.dispatch('storageSetToken', { token: token, permaStorage: permaStorage })

      context.commit('loggedIn', {user, userFullName, userAgentCode, passwordChangeRequired, token});

      context.state.timeout.defaultSeconds = timeoutSeconds
      context.commit('timeoutTimerReset', timeoutSeconds)
      context.commit('timeoutTimerStarted')
      router.push('/')
    },

    timeoutTimerTick(context) {
      context.commit('timeoutTimerTicked')

      if (context.state.timeout.seconds < 0) {
        context.dispatch('timeoutTimerStop')
        context.dispatch('logOut')
      }
       
    },

    timeoutTimerReset(context, {seconds}) {
      context.commit('timeoutTimerReset', seconds)
    },

    timeoutTimerStart(context) {
      context.commit('timeoutTimerStarted')
    },

    timeoutTimerStop(context) {
      context.commit('timeoutTimerStopped')
    },

    timeoutTimerRestart(context) {
      context.commit('timeoutTimerRestarted')
    },


    setCurrentQuoteForPolicyCreation(context, quote) {
      context.commit('currentQuoteSet', quote)
    },

    clearCurrentQuoteForPolicyCreation(context) {
      context.commit('currentQuoteCleared')
    }
  },
  modules: {
  }
})
