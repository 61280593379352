<template>
  <!-- //v1.0.1.4 Εμφάνιση στο ίδιο σημείο με το NewQuoteWizard.vue -->
  <div class="flex flex-col">
    <div class="flex flex-row p-4">
      <div class="flex-col w-3/4">
        <!-- //v1.0.1.4 Εμφάνιση στο ίδιο σημείο με το NewQuoteWizard.vue -->

        <!-- <div class="flex flex-col w-3/4"> -->
        <!-- <div class="flex flex-col w-full px-12 overflow-x-none"> -->
        <custom-card title="Στοιχεία ταξιδιωτών">
          <div
            class="content-center justify-center text-center items-center pt-4"
          >
            <!-- <div class="flex flex-col">
                            <div class="flex flex-row">
                                <div class="mx-2">
                                    <k-input label="Προσφορά #" v-model="policyDetails.quoteId" class="w-60" disabled/>
                                </div>

                                <div class="mx-2">
                                    <k-input label="Χώρα μόνιμης κατοικίας" v-model="policyDetails.residence" class="w-60" disabled/>
                                </div>

                                <div class="mx-2">
                                    <k-input label="Προορισμός" v-model="policyDetails.destination" class="w-60" disabled/>
                                </div>

                                <div class="mx-2">
                                    <k-input label="Πακέτο" v-model="policyDetails.packageName" class="w-60" disabled/>
                                </div>

                            </div>

                            <div class="flex flex-row">
                                <div class="mx-2">
                                    <k-input label="Ημ/νία αναχώρησης" v-model="policyDetails.fromDate" class="w-60" disabled/>
                                </div>

                                <div class="mx-2">
                                    <k-input label="Ημ/νία επιστροφής" v-model="policyDetails.toDate" class="w-60" disabled/>
                                </div>

                                <div class="mx-2">
                                    <k-input label="Πλήθος ταξιδιωτών" v-bind:value="travellers.length" class="w-60" disabled/>
                                </div>

                                <div class="mx-2">
                                    <k-input label="Συνολικό κόστος" v-model="policyDetails.price" class="w-60" disabled/>
                                </div>

                            </div>  
                        </div> -->

            <table class="justify-center table table-auto mt-4">
              <thead>
                <tr>
                  <th>Τίτλος</th>
                  <th>Όνομα</th>
                  <th>Επώνυμο</th>
                  <th>Ηλικιακή ομάδα</th>
                  <th>Ημ/νία γέννησης</th>
                  <th>Ηλικία</th>
                </tr>
              </thead>
              <tbody>
                <template
                  v-for="traveller in travellers"
                  v-bind:key="traveller"
                >
                  <tr>
                    <td class="w-40">
                      <k-drop-down-list
                        :data-items="titles"
                        :default-item="'Παρακαλώ επιλέξτε'"
                        v-model="traveller.title"
                      />
                    </td>
                    <td class="w-60">
                      <k-input v-model="traveller.firstName" class="w-60" />
                    </td>
                    <td class="w-60">
                      <k-input v-model="traveller.lastName" class="w-60" />
                    </td>
                    <td class="w-30">
                      {{ traveller.ageBand.minAge }} -
                      {{ traveller.ageBand.maxAge }}
                    </td>

                    <!-- //v1.0.3.8	NewPolicyRequestForm.vue : Αλλαγή στην εμφάνιση της ημερομηνίας -->
                    <td class="w-60">
                      <k-date-picker
                        class="w-40"
                        :format="'dd/MM/yyyy'"
                        :min="minimumDateOfBirth(traveller)"
                        :max="maximumDateOfBirth(traveller)"
                        v-model="traveller.dateOfBirth"
                      />
                    </td>
                    <!-- //v1.0.3.8	NewPolicyRequestForm.vue : Αλλαγή στην εμφάνιση της ημερομηνίας -->

                    <td class="w-20">
                      {{ getAge(traveller.dateOfBirth) }}
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </custom-card>

        <!------- //v1.0.3.7	Aλλαγές στη μορφοποίηση ------->
        <custom-card title="Στοιχεία πελάτη">
          <div
            class="flex flex-col justify-center content-center text-center container pt-4 w-full"
          >
            <div class="flex flex-row mt-2">
              <div class="mx-2">
                <k-drop-down-list
                  :data-items="legalForms"
                  class="w-60"
                  :default-item="'Παρακαλώ επιλέξτε'"
                  label="Νομική μορφή"
                  v-model="customer.legalForm"
                />
              </div>

              <div v-if="customer.legalForm == 'Individual'">
                <k-drop-down-list
                  :data-items="travellersFullNames"
                  :default-item="'Παρακαλώ επιλέξτε'"
                  class="w-80"
                  label="Επωνυμία πελάτη"
                  v-model="customer.name"
                />
              </div>

              <div v-else-if="customer.legalForm == 'Company'">
                <k-input
                  v-model="customer.name"
                  class="w-60 mx-2"
                  label="Επωνυμία εταιρίας"
                />
                <k-input
                  v-model="customer.vatNumber"
                  class="w-40 mx-2"
                  label="ΑΦΜ"
                />
              </div>
            </div>

            <div class="flex flex-row mt-2">
              <div class="mx-2">
                <k-input v-model="customer.email" class="w-60" label="E-Mail" />
              </div>

              <div class="mx-2">
                <k-input
                  v-model="customer.telephone"
                  class="w-60"
                  label="Αριθμός τηλεφώνου"
                />
              </div>

              <div class="mx-2">
                <k-input
                  v-model="customer.address"
                  class="w-60"
                  label="Διεύθυνση"
                />
              </div>

              <div class="mx-2">
                <k-input
                  v-model="customer.postalCode"
                  class="w-60"
                  label="Ταχ. Κώδικας"
                />
              </div>
            </div>
          </div>
        </custom-card>
      </div>

      <!------- //v1.0.3.7	Aλλαγές στη μορφοποίηση ------->

      <!-- //v1.0.1.3  Η σύνοψη ίδια διάσταση και μορφή στις αποθηκευμένες προσφορές και στις απεσταλμένες αιτήσεις -->
      <custom-card title="Σύνοψη" :extraClass="'w-1/4'">
        <div class="pt-4">
          <table>
            <body>
              <!-- <tr>
                                    <td class="tlabel w-40">Εταιρία</td>
                                    <td class="tvalue">{{ getValue('insuranceCompany')}}</td>
                                </tr>
                                <tr>
                                    <td class="tlabel">Κατηγορία</td>
                                    <td class="tvalue">{{ getValue('travelCategory')}}</td>
                                </tr>
                                <tr>
                                    <td class="tlabel">Τύπος</td>
                                    <td class="tvalue">{{ getValue('travelType')}}</td>
                                </tr> -->

              <tr>
                <td class="tlabel font-bold px-2 pt-2">Προσφορά</td>
                <td class="tvalue pt-2 w-96">#{{ policyDetails.quoteId }}</td>
              </tr>

              <tr>
                <td class="tlabel font-bold px-2 pt-2">Πακέτο</td>
                <td class="tvalue">{{ policyDetails.packageName }}</td>
              </tr>

              <tr>
                <td class="tlabel font-bold px-2 pt-2">Σύνολο</td>
                <td class="tvalue">€ {{ policyDetails.price }}</td>
              </tr>

              <hr class="my-4" />
              <tr>
                <td class="tlabel px-2 pt-2">Αναχώριση</td>
                <td class="tvalue w-96 pt-2">
                  {{ policyDetails.fromDate }}
                </td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Επιστροφή</td>
                <td class="tvalue">{{ policyDetails.toDate }}</td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Κατοικία</td>
                <td class="tvalue">{{ policyDetails.residence }}</td>
              </tr>
              <tr>
                <td class="tlabel px-2 pt-2">Προορισμός</td>
                <td class="tvalue">{{ policyDetails.destination }}</td>
              </tr>

              <tr>
                <td class="tlabel px-2 pt-2">Ταξιδιώτες</td>
                <td class="tvalue">{{ travellers.length }}</td>
              </tr>

              <template v-if="travellersDataFilled">
                <hr class="my-4" />

                <span class="tlabel px-2 pt-2">Στοιχεία ταξιδιωτών</span>

                <br />
                <br />
                <template
                  class="text-center"
                  v-for="traveller in travellers"
                  v-bind:key="traveller"
                >
                  <span>{{
                    `${traveller.title} ${traveller.firstName} ${traveller.lastName}`
                  }}</span>
                  <br />
                </template>
              </template>

              <!--                       


                                <div v-if="policyDetails.extraCoverages?.length > 0">
                                    <hr class="my-4" />

                                    <tr>
                                        <td class="font-bold text-center p-4" colspan="2">Extras</td>
                                    </tr>


                                    <tr v-for="extraCoverage in policyDetails.extraCoverages" v-bind:key="extraCoverage">
                                        <td class="tlabel w-40 py-2 shrink-0">{{extraCoverage.name}}</td>
                                        <td class="tvalue w-40 py-2">{{extraCoverage.value}}</td>
                                    </tr>
                                </div> -->
            </body>
          </table>
        </div>
      </custom-card>
      <!-- //v1.0.1.3  Η σύνοψη ίδια διάσταση και μορφή στις αποθηκευμένες προσφορές και στις απεσταλμένες αιτήσεις -->
    </div>
  </div>
  <br />

  <div class="w-full text-center content-center justify-center">
    <br />
    <span class="k-form-separator mt-8"></span>
    <div class="flex flex-col k-form-buttons">
      <span class="mr-96">
        <spinner-button
          ref="sendRequestButton"
          buttonClass="w-120 px-2 py-1 text-white transition-colors duration-300 bg-[#d1182b] enabled:bg-[#d1182b] rounded-md shadow enabled:hover:bg-red-500 focus:outline-none"
          idleText="Αποστολή ειδοποίησης πληρωμής"
          @idleClick="submitButtonClick"
          :disabled="disableSubmitButton"
        />
      </span>
    </div>
  </div>
</template>

<!--//v1.0.3.7  Aλλαγές στη μορφοποίηση της σύνοψης-->
<style scoped>
.tlabel {
  text-align: left;
  vertical-align: middle;
  font-weight: bolder;
}
.tvalue {
  text-align: right;
  vertical-align: middle;
}
</style>

<!--//v1.0.3.7  Aλλαγές στη μορφοποίηση της σύνοψης-->

<script>
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { Input } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import CustomCard from "@/components/CustomCard.vue";
import { Button } from "@progress/kendo-vue-buttons";
import SpinnerButton from "@/components/SpinnerButton.vue";
export default {
  name: "NewPolicyRequestForm",

  components: {
    "k-drop-down-list": DropDownList,
    "k-input": Input,
    "k-date-picker": DatePicker,
    "k-button": Button,
    CustomCard,
    SpinnerButton,
  },

  inject: ["$appConfig", "kendoForm"],

  props: ["policyDetailsProp"],

  data: function () {
    return {
      value: null,
      legalForms: ["Individual", "Company"],
      titles: ["Mr.", "Ms.", "Mrs."],
      travellers: [],

      policyDetails: {
        quoteId: null,
        packageName: null,
        price: null,
        origin: null,
        destination: null,
        fromDate: null,
        toDate: null,
        personsPerAgeBand: [],
      },

      customer: {
        legalForm: null,
        name: null,
        vatNumber: null,
        address: null,
        postalCode: null,
        telephone: null,
        email: null,
      },
    };
  },

  mounted() {
    // console.log(this.policyDetailsProp);

    this.policyDetails = this.$props.policyDetailsProp;

    this.generateTravellers();
  },

  methods: {
    getAge(dateOfBirth) {
      if (dateOfBirth == null) return "";

      var ageDifMs = Date.now() - dateOfBirth;
      var ageDate = new Date(ageDifMs); // miliseconds from epoch

      const age = Math.abs(ageDate.getUTCFullYear() - 1970);

      // return `${age}-${age+1}`;

      // return `${age}-${age+1}`;
      //v1.0.2.8  Έλεγχος ηλικίας (if is older than 120) να μην εμφαβίζεται στο td element μετα την επιλογή ημερομηνίας γέννησης

      if (age > 120) {
        return "";
      } else {
        return age;
      }

      //v1.0.2.8  Έλεγχος ηλικίας (if is older than 120) να μην εμφαβίζεται στο td element μετα την επιλογή ημερομηνίας γέννησης
    },

    generateTravellers() {
      let travellers = [];

      if (this.policyDetails == null) return;

      this.policyDetails.personsPerAgeBand.forEach(function (item) {
        for (let i = 1; i <= item.persons; i++) {
          let thisTraveller = {
            title: null,
            firstName: null,
            lastName: null,
            dateOfBirth: null,
            ageBand: item.ageBand,
          };

          travellers.push(thisTraveller);
        }
      });

      this.travellers = travellers;
    },

    maximumDateOfBirth(traveller) {
      function subYears(years) {
        var today = new Date();

        var year = today.getFullYear();
        var month = today.getMonth();
        var day = today.getDate();
        var c = new Date(year - years, month, day);

        return c;
      }

      const c = subYears(traveller.ageBand.minAge);

      return c;
    },

    minimumDateOfBirth(traveller) {
      function subYears(years) {
        var today = new Date();

        var year = today.getFullYear();
        var month = today.getMonth();
        var day = today.getDate();
        var c = new Date(year - years, month, day);

        return c;
      }

      return subYears(traveller.ageBand.maxAge);
    },

    submitButtonClick() {
      this.$refs.sendRequestButton.setStateActive();

      // kua api update offer
      const policyholderDetails = {
        LegalForm: this.customer.legalForm,
        FullOrCompanyName: this.customer.name,
        VATNumber:
          this.customer.legalForm == "Individual"
            ? "000000000"
            : this.customer.vatNumber,
        email: this.customer.email,
        address: this.customer.address,
        postcode: this.customer.postalCode,
        telephone: this.customer.telephone,
      };

      const travellers = this.travellers.map((x) => ({
        lead: "",
        title: x.title,
        firstname: x.firstName,
        lastname: x.lastName,
        dob: x.dateOfBirth.toLocaleDateString("el-GR", {
          month: "2-digit",
          day: "2-digit",
          year: "numeric",
        }),
        // age: this.getAge(x.dob),
        AgeBandId: x.ageBand.id,
      }));

      const apiUpdatePayload = {
        token: this.kendoForm.values.apiToken,
        quoteID: this.policyDetails.quoteId,
        travellers: travellers,
        PolicyholderDetails: [policyholderDetails],
      };

      const targetUrl = this.requestTravelPolicyIssueUrl.href;

      this.axios
        .post(targetUrl, apiUpdatePayload)
        .then((response) => {
          this.$refs.sendRequestButton.setStateIdle();
          if (response.data.ErrorCode)
            this.$swal.fire("Σφάλμα", response.data.ErrorDescription, "error");
          else
            this.$swal
              .fire("Επιτυχία", "Το αίτημα καταχωρήθηκε", "success")
              .then(() => this.$router.push("/travel/policy-requests"));
        })
        .catch((error) => {
          this.$refs.sendRequestButton.setStateIdle();
          this.$swal.fire(
            "Σφάλμα",
            "Το αίτημα δεν μπορεί να καταχωρηθεί",
            "error"
          );
        });
    },
  },

  computed: {
    requestTravelPolicyIssueUrl() {
      return new URL("/travel/update_policy_details", this.$appConfig.KUA_API_BASE_URL);
    },

    travellersFullNames() {
      return this.travellers
        .filter(
          (x) =>
            x.firstName != null &&
            x.lastName != null &&
            this.getAge(x.dateOfBirth) >= 18
        )
        .map((x) => `${x.title} ${x.firstName} ${x.lastName}`);
    },

    customerDataFilled() {
      return (
        this.customer.address?.length > 3 &&
        this.customer.postalCode?.length > 2 &&
        this.customer.telephone?.length > 9 &&
        this.customer.email?.length > 5 &&
        ((this.customer.legalForm == "Individual" &&
          this.customer.name?.length > 3) ||
          (this.customer.legalForm == "Company" &&
            this.customer.vatNumber?.length > 8))
      );
    },

    travellersDataFilled() {
      const travellerDataFilled = (traveller) =>
        traveller.title?.length > 0 &&
        traveller.firstName?.length > 1 &&
        traveller.lastName?.length > 1 &&
        traveller.dateOfBirth != null;

      const travellerChecks = this.travellers.map((x) =>
        travellerDataFilled(x)
      );

      if (travellerChecks.filter((x) => x == false).length > 0) return false;

      return true;
    },

    disableSubmitButton() {
      if (this.customerDataFilled == false) return true;

      if (this.travellersDataFilled == false) return true;

      return false;
    },
  },

  watch: {
    "customer.legalForm": function () {
      this.customer.name = null;
      this.customer.vatNumber = null;
    },
  },
};
</script>
