<template>
  <meta charset="UTF-8" />
  <div class="flex flex-row w-full">
    <custom-card
      title="Αναφορά Παραγωγής - Κριτήρια αναζήτησης"
      class="mt-2"
      extraClass="w-full"
    >
      <div>
        <div class="flex flex-row mt-8 items-center justify-center">
          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="startDateInput"
              >ΑΠΟ ΗΜΕΡΟΜΗΝΙΑ</label
            >
            <k-date-picker
              id="startDateInput"
              :format="'dd/MM/yyyy'"
              :value="startDateFilter"
              :max="maxStartDateFilterValue"
              @change="startDateChanged"
            >
              <span class="close-btn">XXXX</span>
            </k-date-picker>
          </div>

          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="endDateInput"
              >ΕΩΣ ΗΜΕΡΟΜΗΝΙΑ</label
            >
            <k-date-picker
              id="endDateInput"
              :format="'dd/MM/yyyy'"
              :value="endDateFilter"
              :min="minEndDateFilterValue"
              @change="endDateChanged"
            >
              <!-- <span class="close-btn" @click="clear">WTFISTHISSHIT</span> -->
            </k-date-picker>
          </div>

          <!-- ΠΕΡΙΤΤΟ ΑΦΟΥ ΘΑ ΕΙΝΑΙ ΜΟΝΟ ΚΑΡΑΒΙΑΣ-->
          <!--                     
                    <div class="flex flex-col mx-2 w-60">
                        <label class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2" for="insuranceCompanyInput">ΑΣΦΑΛΙΣΤΙΚΗ ΕΤΑΙΡΙΑ</label>
                        <k-multi-select
                            id="insuranceCompanyInput" 
                            :checkboxes="true"
                            :data-items="filterValues?.insuranceCompanies ?? []" v-model="insuranceCompanyFilter">
                        </k-multi-select>
                    </div> -->

          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="insuranceCompanyInput"
              >ΚΛΑΔΟΣ ΑΣΦΑΛΙΣΗΣ</label
            >
            <k-multi-select
              id="insuranceCompanyInput"
              :filterable="true"
              :data-items="filterValues?.insuranceBranches ?? []"
              :text-field="'description'"
              :data-item-key="'code'"
              v-model="insuranceBranchesFilter"
            >
            </k-multi-select>
          </div>

          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="contractStatusInput"
              >ΚΑΤΑΣΤΑΣΗ ΣΥΜΒΟΛΑΙΟΥ</label
            >
            <k-multi-select
              id="contractStatusInput"
              :filterable="true"
              :data-items="contractStatuses"
              v-model="contractStatusFilter"
            >
            </k-multi-select>
          </div>
        </div>

        <div class="flex flex-row justify-center mt-4">
          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="licTradNumInput"
              >ΑΦΜ ΣΥΜΒΑΛΛΟΜΕΝΟΥ</label
            >
            <k-input id="licTradNumInput" v-model="licTradNumFilter"> </k-input>
          </div>

          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="documentNumberInput"
              >ΑΡ. ΣΥΜΒΟΛΑΙΟΥ</label
            >
            <k-input id="documentNumberInput" v-model="docNoFilter"> </k-input>
          </div>

          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="agentCodeInput"
              >ΚΩΔ. ΣΥΝΕΡΓΑΤΗ COMMISSION</label
            >
            <!-- <k-input id="agentCodeInput" v-model="commissionAgentCodeFilter"> All_AgentCodes
            </k-input> -->
            <k-multi-select
              id="agentCodeInput"
              :filterable="true"
              :data-items="this.filterValues?.allNNAgentCod ?? []"
              v-model="commissionAgentCodeFilter"
            >
            </k-multi-select>
            <transition name="appear">
              <k-checkbox
                class="appear"
                :class="{ invisible: !showAllCommissionAgentCodesCheckbox }"
                :label="'Όλοι οι κωδικοί συνεργάτη'"
                v-model="allCommissionAgentCodesFilter"
                :default-checked="false"
              />
            </transition>
          </div>

          <div class="flex flex-col mx-2 w-60">
            <label
              class="block uppercase tracking-wide text-grey-darker text-xs font-bold mb-2"
              for="serviceCodeInput"
              >ΚΩΔ. ΣΥΝΕΡΓΑΤΗ SERVICE</label
            >
            <!-- <k-input id="agentCodeInput" v-model="serviceAgentCodeFilter">
            </k-input> -->

            <k-multi-select
              id="serviceCodeInput"
              :filterable="true"
              :data-items="this.filterValues?.allNNSrvcCode ?? []"
              v-model="serviceAgentCodeFilter"
            >
            </k-multi-select>
            <transition name="appear">
              <k-checkbox
                class="appear"
                :class="{ invisible: !showAllServiceAgentCodesCheckbox }"
                :label="'Όλοι οι κωδικοί συνεργάτη'"
                v-model="allServiceAgentCodesFilter"
                :default-checked="false"
              />
            </transition>
          </div>
        </div>

        <!-- <k-button class="my-8 mx-2 px-2 w-40" :theme-color="'primary'" @click="loadData">ΑΝΑΖΗΤΗΣΗ</k-button> -->
        <spinner-button
          ref="searchButton"
          buttonClass="w-40 px-2 py-1 text-white transition-colors duration-300 bg-red-500 rounded-md shadow enabled:hover:bg-red-600 focus:outline-none"
          idleText="ΑΝΑΖΗΤΗΣΗ"
          @idleClick="loadData"
        />
        <k-button
          class="my-8 mx-2 px-2 w-40"
          :theme-color="'secondary'"
          @click="clearFilters"
          >ΚΑΘΑΡΙΣΜΑ</k-button
        >

        <!-- <k-button class="my-8 mx-2 px-2 w-40" :theme-color="'success'" @click="exportExcel" :disabled="this.gridData.length == 0">ΕΞΑΓΩΓΗ (XLS)</k-button> -->
        <spinner-button
          ref="exportExcelButton"
          buttonClass="w-40 px-2 py-1 text-white transition-colors duration-300 bg-green-500 rounded-md shadow enabled:hover:bg-green-600 focus:outline-none"
          idleText="ΕΞΑΓΩΓΗ"
          @idleClick="exportExcel"
          :disabled="gridData.length == 0"
        />
      </div>
    </custom-card>
  </div>

  <div class="flex flex-row w-full">
    <!-- :column-menu="columnMenu" -->
    <CustomCardGrid class="mt-2 h-full">
      <k-grid
        ref="grid"
        :data-items="processedGridData"
        :selected-field="selectedField"
        :columns="columns"
        :sortable="true"
        :sort="gridSort"
        :filter="gridFilter"
        :style="{ height: '500px', maxHeight: '600px' }"
        :filterable="filterable"
        :reorderable="reorderable"
        :resizable="resizable"
        :group="group"
        @sortchange="gridSortChangeHandler"
        @filterchange="gridFilterChangeHandler"
        @rowclick="onRowClick"
        @datastatechange="dataStateChange"
        @columnreorder="columnReorder"
        @columnresize="columnResize"
      >
        <!-- <template v-slot:myColumnMenuTemplate="{ props }">
          <div>
            <ColumnMenu
              :column="props.column"
              :filterable="props.filterable"
              :filter="props.filter"
              :sortable="props.sortable"
              :sort="props.sort"
              :columns="columns"
              :locked="isColumnLocked(props.column.field)"
              @sortchange="(e) => props.onSortchange(e)"
              @lockchange="(e) => lockChange(e, props.column)"
              @visibilitychange="(e) => visibilityChange(e, props.column)"
              @filterchange="(e) => props.onFilterchange(e)"
              @closemenu="(e) => props.onClosemenu(e)"
              @contentfocus="(e) => props.onContentfocus(e)"
              @columnssubmit="onColumnsSubmit"
            />
          </div>
          licTradNum
        </template> -->
        <template v-slot:myTemplate="{ props }">
          <div style="display: table-cell; vertical-align: middle">
            <span v-if="props.dataItem.fileName != null">
              <k-button
                icon="download"
                theme-color="primary"
                fill-mode="flat"
                @click="download(props.dataItem.fileName, props.dataItem)"
              >
                Λήψη
              </k-button>
            </span>
            <span v-else> &nbsp;. </span>
          </div>
        </template>
        <template v-slot:myTemplateVat="{ props }">
          <div style="display: table-cell; vertical-align: middle">
            <span
              v-if="props.dataItem.licTradNum.length > 0"
              @click="clickTest"
            >
              <k-button>
                {{ props.dataItem.licTradNum }}
              </k-button>
            </span>

            <span v-else> &nbsp;. </span>
          </div>
        </template>
      </k-grid>
      <k-dialog
        v-if="isVisible"
        :title="'Βασικά Στοιχεία Πελάτη'"
        @close="clickTest"
      >
        <div
          class="flex flex-col space-y-1"
          style="height: 470px; width: 550px"
        >
          <div class="flex flex-col space-y-1">
            <div
              class="flex flex-row items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="cardName">Επωνυμία:</label>
              </span>
              <span
                id="cardName"
                class="break-words text-left"
                style="padding-left: 119px"
                v-for="vat in gridVat"
                >{{ vat.cardName }}</span
              >
            </div>
          </div>

          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="nnAgentCod">NN Συνεργάτης προμήθειας:</label>
              </span>
              <span id="nnAgentCod" class="text-left" v-for="vat in gridVat">{{
                vat.nnAgentCod
              }}</span>
            </div>
          </div>

          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="nnSrvcCode">NN Συνεργάτης service:</label>
              </span>
              <span
                id="nnSrvcCode"
                class="text-left"
                style="padding-left: 32px"
                v-for="vat in gridVat"
                >{{ vat.nnSrvcCode }}</span
              >
            </div>
          </div>

          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="phone1">Τηλέφωνο:</label>
              </span>
              <span
                id="phone1"
                class="text-left"
                style="padding-left: 120px"
                v-for="vat in gridVat"
                >{{ vat.phone1 }}</span
              >
            </div>
          </div>

          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="cellular">Κιν. Τηλέφωνο:</label>
              </span>
              <span
                id="cellular"
                class="text-left"
                style="padding-left: 91px"
                v-for="vat in gridVat"
                >{{ vat.cellular }}</span
              >
            </div>
          </div>
          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="e_Mail">Email:</label>
              </span>
              <span
                id="e_Mail"
                class="text-left"
                style="padding-left: 153px"
                v-for="vat in gridVat"
                >{{ vat.e_Mail }}</span
              >
            </div>
          </div>
          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="notes">Σημειώσεις:</label>
              </span>
              <span
                id="notes"
                class="text-left"
                style="padding-left: 116px"
                v-for="vat in gridVat"
                >{{ vat.notes }}</span
              >
            </div>
          </div>
          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="street">Οδός:</label>
              </span>
              <span
                id="street"
                style="padding-left: 154px"
                class="text-left"
                v-for="vat in gridVat"
                >{{ vat.street }}</span
              >
            </div>
          </div>
          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="city">Πόλη:</label>
              </span>
              <span
                id="city"
                style="padding-left: 154px"
                class="text-left"
                v-for="vat in gridVat"
                >{{ vat.city }}</span
              >
            </div>
          </div>
          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="zipCode">Τ.Κ.:</label>
              </span>
              <span
                id="zipCode"
                style="padding-left: 164px"
                class="text-left"
                v-for="vat in gridVat"
                >{{ vat.zipCode }}</span
              >
            </div>
          </div>
          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="licTradNum">ΑΦΜ:</label>
              </span>
              <span
                id="licTradNum"
                style="padding-left: 157px"
                class="text-left"
                v-for="vat in gridVat"
                >{{ vat.licTradNum }}</span
              >
            </div>
          </div>
          <div class="flex flex-col space-y-1">
            <div
              class="flex items-center bg-white rounded shadow-md mb-4 col-span-2"
            >
              <span class="px-3 font-bold text-left">
                <label for="county">Χώρα:</label>
              </span>
              <span
                id="county"
                style="padding-left: 150px"
                class="text-left"
                v-for="vat in gridVat"
                >{{ vat.county }}</span
              >
            </div>
          </div>
        </div>
      </k-dialog>
      <!-- </div> -->
    </CustomCardGrid>
  </div>
</template>
<!-- myTemplateVat -->
<style>
.kgrid-td-rightalign {
  text-align: right !important;
}

.k-grid td {
  white-space: nowrap;
}
</style>

<style scoped>
/* .k-dialog {
  background-color: red;
  color: black;
}
.myClass kendo-dialog-titlebar{
        background-color: red;
} */

.k-grid td {
  white-space: nowrap;
}

.expand {
  animation: slideDown 0.2s linear;
  overflow: hidden;
}

.expand-leave-active.expand-leave-to {
  transition: height 0.2s linear;
  height: 0;
}

@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: 20px;
  }
}

.appear {
  animation: ease-in-out 0.2s linear;
}

.appear-leave-active.appear-leave-to {
  transition: ease-in-out 0.2s linear;
}
</style>

<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import { process } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Window } from "@progress/kendo-vue-dialogs";

import {
  DropDownList,
  MultiSelect,
  MultiSelectTree,
} from "@progress/kendo-vue-dropdowns";
import {
  processMultiSelectTreeData,
  expandedState,
} from "@/components/multiselecttree-data-operations";
import { data } from "@/components/tree-data";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { Input } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { filterBy } from "@progress/kendo-data-query";
import { toRaw } from "vue";
import CustomCard from "@/components/CustomCard.vue";
import CustomCardGrid from "@/components/CustomCardGrid.vue";
import SpinnerButton from "@/components/SpinnerButton.vue";

import fileDownload from "js-file-download";

import ColumnMenu from "@/components/ColumnMenu.vue";

import "@progress/kendo-theme-default/dist/all.css";
import {
  IntlProvider,
  load,
  LocalizationProvider,
  loadMessages,
  IntlService,
} from "@progress/kendo-vue-intl";
const dataItemKey = "id";
const checkField = "checkField";
const checkIndeterminateField = "checkIndeterminateField";
const subItemsField = "items";
const expandField = "expanded";
const textField = "text";

const fields = {
  dataItemKey,
  checkField,
  checkIndeterminateField,
  expandField,
  subItemsField,
};
const allData = [
  { id: 1, text: "Small" },
  { id: 2, text: "Medium" },
  { id: 3, text: "Large" },
];
const DATE_FORMAT = "dd/mm/yyyy";
const intl = new IntlService();
function formatDates(items, noFormat) {
  items.forEach((o) => {
    // o.cntrctStrD = intl.parseDate(
    //   o.cntrctStrD,
    //   noFormat ? DATE_FORMAT : DATE_FORMAT
    //);
    // console.log(o.cntDueDate);

    var startDate = new Date(o.cntrctStrD);
    var effectDate = new Date(o.effectDate);
    var cntDueDate = new Date(o.cntDueDate);

    //console.log(Intl.DateTimeFormat('en-GB').format(d)); // Hours
    o.cntrctStrD = startDate;
    o.effectDate = effectDate;
    o.cntDueDate = cntDueDate;
    // console.log(d.getUTCDay());
    // console.log(d.getUTCFullYear());

    // o.cntrctStrD =
    //   o.cntrctStrD === "NULL"
    //     ? undefined
    //     : intl.parseDate(o.cntrctStrD, "dd/MM/yyyy" ? DATE_FORMAT : undefined);
    //console.log(Intl.DateTimeFormat('en-GB').format(o.cntrctStrD));
  });
  return items;
}

export default {
  name: "ReportsView",
  inject: ["$appConfig"],
  components: {
    "k-grid": Grid,
    "k-grid-toolbar": GridToolbar,
    "k-button": Button,
    "k-dialog": Dialog,
    "k-dialog-actions-bar": DialogActionsBar,
    DropDownList,
    "k-date-picker": DatePicker,
    "k-multi-select": MultiSelect,
    "k-input": Input,
    "k-checkbox": Checkbox,
    CustomCard,
    CustomCardGrid,
    SpinnerButton,
    ColumnMenu,
    window: Window,
  },
  data() {
    return {
      isVisible: false,
      columnMenu: true,
      sortable: true,
      filterable: true,
      groupable: true,
      reorderable: true,
      resizable: true,

      showFilters: true,

      filterValues: null,
      threelettersAgentCode: null,

      startDateFilter: null,
      endDateFilter: null,

      insuranceCompanyFilter: null,
      insuranceBranchesFilter: null,

      contractTypeFilter: null,

      commissionAgentCodeFilter: null,
      allCommissionAgentCodesFilter: null,

      serviceAgentCodeFilter: null,
      allServiceAgentCodesFilter: null,

      licTradNumFilter: null,
      docNoFilter: null,
      contractStatusFilter: null,

      contractStatuses: ["ΕΝΕΡΓΟ", "ΑΚΥΡΟ", "ΜΗ ΕΝΕΡΓΟ"],
      gridData: [],
      gridDataParsed: [],
      columnsVat: [
        {
          title: "ΑΦΜ",
          filterable: false,
          width: "120",
          field: "licTradNum",
        },
        {
          width: "220px",
          field: "cardName",
          //editable: false,
          title: "Επωνυμία",
          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "nnAgentCod",
          // editable: false,
          title: "NN Συνεργάτης προμήθειας",
          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "nnSrvcCode",
          title: "NN Συνεργάτης service",
          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "phone1",
          title: "Τηλέφωνο",
          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "cellular",
          title: "Κιν. Τηλέφωνο",
          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "fax",
          title: "ΦΑΞ",
          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "e_mail",
          title: "Email",
          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "220px",
          field: "notes",
          title: "Σημειώσεις",
          hidden: false,
          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "street",
          title: "Οδός",
          hidden: false,

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "city",
          title: "Πόλη",
          hidden: false,

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "210px",
          field: "zipCode",
          title: "Τ.Κ.",
          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "county",
          title: "Χώρα",
          // columnMenu: "myColumnMenuTemplate",
        },
      ],

      columns: [
        {
          title: "Συμβόλαιο",
          cell: "myTemplate",
          filterable: false,
          width: "120",
        },
        {
          width: "220px",
          field: "nnAgentCod",
          //editable: false,
          title: "NN Συνεργάτης προμήθειας",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "nnSrvcCode",
          // editable: false,
          title: "NN Συνεργάτης service",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "kwdikosKladou",
          editable: false,
          title: "Κλάδος",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "typosSymbolaiou",
          editable: false,
          title: "Τύπος κίνησης",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "numAtCard",
          editable: false,
          title: "Αρ. Συμβολαίου",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "recptNum",
          editable: false,
          title: "Αρ. Απόδειξης",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "docNum",
          editable: false,
          title: "Αριθμός εγγραφής KUA",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "220px",
          field: "cntrctStrD",
          editable: false,
          title: "Ημ/νία έναρξης συμβολαίου",
          type: "date",
          format: "{0:dd/MM/yyyy}",
          filter: "date",
          hidden: false,

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "effectDate",
          editable: false,
          title: "Ημ/νια έναρξης κάλυψης",
          filter: "date",
          type: "date",
          format: "{0:dd/MM/yyyy}",
          hidden: false,

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "cntDueDate",
          editable: false,
          title: "Ημ/νια λήξης συμβολαίου",
          filter: "date",
          type: "date",
          format: "{0:dd/MM/yyyy}",
          hidden: false,

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "210px",
          field: "cardName",
          editable: false,
          title: "Επωνυμία συμβαλλόμενου",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "licTradNum",
          editable: false,
          title: "ΑΦΜ",
          cell: "myTemplateVat",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "katharaAsfalistra",
          editable: false,
          title: "Καθαρά ασφάλιστρα",
          format: "{0:n2}",
          filter: "numeric",
          className: "kgrid-td-rightalign",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "docTotal",
          editable: false,
          title: "Μεικτά ασφάλιστρα",
          format: "{0:n2}",
          filter: "numeric",
          className: "kgrid-td-rightalign",

          // columnMenu: "myColumnMenuTemplate",
        },
        {
          width: "200px",
          field: "contractStatus",
          editable: false,
          title: "Κατάσταση συμβολαίου",

          // columnMenu: "myColumnMenuTemplate",
        },
      ],
      selectedField: "selected",

      gridSort: null,
      gridFilter: null,
      group: [],
      vatNum: null,
      gridVat: [],
    };
  },

  mounted() {
    // this.loadData()
    this.loadFilterValues();
  },

  methods: {
    // toggleDialog() {
    //   this.isVisible = !this.isVisible;
    // },
    clickTest(event) {
      this.vatNum = event.target.innerText;
      this.vatData();
      console.log(this.isVisible);
      this.isVisible = !this.isVisible;
    },
    vatData() {
      this.gridVat = [];
      // this.$refs.searchButton.setStateActive();

      const url = this.vatDataUrl.href;

      let params = {};

      // const tryAdd = (paramsObject, filterName, filterValue) => {
      //   if (filterValue == null) return paramsObject;

      //   paramsObject[filterName] = filterValue;
      //   return paramsObject;
      // };

      params = { licTradNum: this.vatNum };
      console.log(params);
      // console.log(params)

      this.axios
        .get(url, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
          params: params,
        })
        .then((response) => {
          // this.gridDataParsed = formatDates(response.data, true);
          //  this.gridData = this.gridDataParsed;
          this.gridVat = response.data;
          // this.$refs.searchButton.setStateIdle();
          // if (this.gridData.length > 0)
          //     this.showFilters = false;
          console.log(this.gridVat);
        })
        .catch((error) => {
          this.$refs.searchButton.setStateIdle();
        });
    },
    filterChange(event) {
      this.items = this.filterData(event.filter);
    },
    filterData(filter) {
      const data = allData.slice();
      return filterBy(data, filter);
    },
    onExpandChange(event) {
      this.expanded = expandedState(event.item, dataItemKey, this.expanded);
    },
    pageChangeHandler: function (event) {
      this.loader = false;
      this.gridSkip = event.page.gridSkip;
      this.gridTake = event.page.gridTake;
    },
    isColumnLocked(columnName) {
      return this.columns.filter((ev) => ev.field === columnName)[0].locked;
    },
    lockChange(state, columnName) {
      const columnToLock = this.columns.filter(
        (ev) => ev.field === columnName.field
      )[0];
      columnToLock.locked = state;
    },
    visibilityChange(state, columnName) {
      this.columns.filter((ev) => ev.field === columnName.field)[0].hidden =
        state;
    },
    onColumnsSubmit(columnsState) {
      this.columns = columnsState;
    },
    createAppState: function (dataState) {
      this.take = dataState.take;
      this.skip = dataState.skip;
      if (dataState.group) {
        dataState.group.map((group) => (group.aggregates = this.aggregates));
      }
      this.group = dataState.group;
      this.filter = dataState.filter;
      this.sort = dataState.sort;
    },
    dataStateChange(event) {
      this.createAppState(event.data);
      this.dataResult = process(this.gridData, {
        skip: this.skip,
        take: this.take,
        sort: this.sort,
        filter: this.filter,
        group: this.group,
      });
    },
    columnReorder: function (options) {
      this.columns = options.columns;
    },
    columnResize: function (options) {
      this.columns = options.columns;
    },
    onRowClick(event) {
      if (event.dataItem)
        event.dataItem[this.selectedField] =
          !event.dataItem[this.selectedField];
    },
    clearFilters() {
      this.startDateFilter = null;
      this.endDateFilter = null;
      this.insuranceCompanyFilter = null;
      this.insuranceBranchesFilter = [];

      this.licTradNumFilter = null;
      this.commissionAgentCodeFilter = [];
      this.docNoFilter = null;

      this.serviceAgentCodeFilter = null;
      this.contractStatusFilter = null;

      this.gridFilter = null;
      this.gridData = [];
      this.gridDataParsed = [];
    },

    newReport() {
      //clear filters, maybe?

      //and clear data
      this.gridData = null;
    },

    loadData() {
      this.gridData = [];
      this.$refs.searchButton.setStateActive();

      const url = this.reportUrl.href;

      let params = {};

      const tryAdd = (paramsObject, filterName, filterValue) => {
        if (filterValue == null) return paramsObject;

        paramsObject[filterName] = filterValue;
        return paramsObject;
      };

      let setEndOfDay = (date) => {
        if (date) {
          date.setHours(23);
          date.setMinutes(59);
          date.setSeconds(59);

          return date;
        }

        return date;
      };

      let processedEndDateFilter = setEndOfDay(this.endDateFilter);

      params = tryAdd(params, "startDate", this.startDateFilter);
      params = tryAdd(params, "endDate", processedEndDateFilter);
      params = tryAdd(params, "licTradNum", this.licTradNumFilter);
      params = tryAdd(params, "insuranceCompany", this.insuranceCompanyFilter);
      params = tryAdd(
        params,
        "insuranceBranch",
        this.insuranceBranchesFilter?.map((x) => x.code)
      );

      params = tryAdd(params, "documentNumber", this.docNoFilter);
      params = tryAdd(params, "contractStatus", this.contractStatusFilter);
      params = tryAdd(
        params,
        "commissionAgentCode",
        this.commissionAgentCodeFilter
      );
      params = tryAdd(params, "serviceAgentCode", this.serviceAgentCodeFilter);

      params = tryAdd(
        params,
        "allCommissionAgentCodes",
        this.allCommissionAgentCodesFilter
      );
      params = tryAdd(
        params,
        "allServiceAgentCodes",
        this.allServiceAgentCodesFilter
      );

      // console.log(params)

      this.axios
        .get(url, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
          params: params,
        })
        .then((response) => {
          this.gridDataParsed = formatDates(response.data, true);
          //  this.gridData = this.gridDataParsed;
          this.gridData = response.data;
          this.$refs.searchButton.setStateIdle();
          // if (this.gridData.length > 0)
          //     this.showFilters = false;
          console.log(this.gridData);
        })
        .catch((error) => {
          this.$refs.searchButton.setStateIdle();
        });
    },

    loadFilterValues() {
      const url = this.reportFilterValuesUrl.href;

      this.axios
        .get(url, {
          headers: { Authorization: "Bearer " + this.$store.state.token },
        })
        .then((response) => (this.filterValues = response.data));
    },
    gridSortChangeHandler(event) {
      this.gridSort = event.sort;
    },
    gridFilterChangeHandler(event) {
      this.gridFilter = event.filter;
      // console.log(this.gridFilter)
    },
    gridPageChangeHandler(event) {
      this.gridTake = event.page.take;
      this.gridSkip = event.page.skip;
    },

    exportExcel() {
      const nowString = new Date().toISOString();
      const date = nowString.split("T")[0];
      const time = nowString.split("T")[1].split(".")[0];

      const d = date.replace("-", "").replace("-", "");
      const t = time.replace(":", "").replace(":", "");

      const ts = `${d}${t}`;

      saveExcel({
        columns: this.columns.map((item) => {
          return { ...item, hidden: false };
        }),
        data: this.gridData,
        fileName: `kua-nn-portal-production-report-${ts}`,
      });
    },

    startDateChanged(event) {
      this.startDateFilter = event.value;
    },

    endDateChanged(event) {
      this.endDateFilter = event.value;
    },

    async download(token, str_CustomElement) {
      if (token == null) {
        this.$swal.fire("Σφάλμα", "Το αρχείο δεν είναι διαθέσιμο", "error");
        // console.log("eimai sto panw")
        return;
      }

      // this.$refs.filegardenPostFormTokenInput.value = token;
      // this.$refs.filegardenPostForm.submit();

      // this.$refs.filegardenPostFormTokenInput.value = '';

      // const originalFileExtension = upload.fileInfo.originalFilename.match(/.+\.(.+?)$/)[1]
      // const fileName = crypto.randomUUID();
      const fileName =
        str_CustomElement.docNum +
        "_" +
        str_CustomElement.numAtCard +
        "_" +
        str_CustomElement.recptNum; //window.crypto.randomUUID(); //

      // const targetFilename = `nnportal-contract-${fileName}.${originalFileExtension}`

      // const targetFilename = `${fileName}.pdf`;
      // const targetFilename = `test.pdf`;
      const targetFilename = `${fileName}`;

      try {
        const response = await this.axios.post(
          this.filegardenPostDownloadUrl,
          { token: token },
          {
            responseType: "blob",
          }
        );
        const data = response.data;

        fileDownload(data, targetFilename, data.type);
      } catch (error) {
        this.$swal.fire("Σφάλμα", "Το αρχείο δεν είναι διαθέσιμο", "error");
        console.log(error);
      }
    },
  },

  computed: {
    filegardenPostDownloadUrl() {
      return new URL("/api/filegarden/download", this.$appConfig.API_BASE_URL);
    },

    minEndDateFilterValue() {
      if (this.startDateFilter) return this.startDateFilter;
    },

    maxStartDateFilterValue() {
      if (this.endDateFilter) return this.endDateFilter;
    },

    showAllCommissionAgentCodesCheckbox() {
      return this.commissionAgentCodeFilter?.length > 0;
    },

    showAllServiceAgentCodesCheckbox() {
      return this.serviceAgentCodeFilter?.length > 0;
    },

    gridTotal() {
      return this.gridData?.length ?? 0;
    },

    reportUrl() {
      return new URL(
        this.$appConfig.API_PRODUCTION_REPORT_URL,
        this.$appConfig.API_BASE_URL
      );
    },
    vatDataUrl() {
      return new URL(
        this.$appConfig.API_CUSTOMERS_DATA,
        this.$appConfig.API_BASE_URL
      );
    },

    reportFilterValuesUrl() {
      return new URL(
        this.$appConfig.API_REPORTS_DROPDOWN_FILTER_VALUES,
        this.$appConfig.API_BASE_URL
      );
    },

    processedGridData() {
      return process(this.gridData, {
        sort: this.gridSort,
        filter: this.gridFilter,
        take: this.gridTake,
        skip: this.gridSkip,
      });
    },
    processedVatGridData() {
      return process(this.gridVat, {
        sort: this.gridSort,
        filter: this.gridFilter,
        take: this.gridTake,
        skip: this.gridSkip,
      });
    },
  },
  created() {
    this.threelettersAgentCode = this.filterValues?.allAgentCodes.slice();
    console.log(this.filterValues?.allAgentCodes);
  },
};
</script>
