<template>
   
  <div
    class="bg-gray-100 asd mx-2 my-8 shadow-md rounded-lg overflow-hidden"
    v-bind:class="extraClass"
  >
  <!-- //v1.0.3.4  Αλλαγή χρώματος γραμμής -->
    <div class="px-2 py-2 divide-y divide-y-2 divide-[#d1182b]">
  <!-- //v1.0.3.4  Αλλαγή χρώματος γραμμής -->
      <p class="px-2 mb-1 text-gray-900 font-semibold text-left">{{ title }}</p>
      <slot></slot>
    </div>
  </div>
   
</template>

<script>
export default {
  props: ["title", "extraClass"],
};
</script>
