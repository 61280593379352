<template>
   
  <div
    class="bg-gray-100 asd mx-2 my-8 shadow-md overflow-hidden"
    v-bind:class="extraClass"
  >
  <!-- //v1.0.3.4  Αλλαγή χρώματος γραμμής -->
    <div  >
  <!-- //v1.0.3.4  Αλλαγή χρώματος γραμμής -->
       
      <slot></slot>
    </div>
  </div>
   
</template>

<script>
export default {
  props: [  "extraClass"],
};
</script>
