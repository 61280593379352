<template>
  <!--Start of FUCKERY -->
  <k-dialog
    v-if="addUserVisible"
    :title="'New user details'"
    @close="toggleAddUser"
  >
    <div class="flex flex-col space-y-1 px-8">
      <div class="flex flex-col space-y-1">
        <div class="flex items-center bg-white rounded shadow-md mb-4">
          <span class="px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"
              />
              <circle cx="12" cy="10" r="3" />
              <circle cx="12" cy="12" r="10" />
            </svg>
          </span>
          <input
            class="w-full h-12 focus:outline-none"
            type="text"
            placeholder="Full Name"
            v-model="newUser.fullName"
          />
        </div>
      </div>

      <div class="flex flex-col space-y-1">
        <div class="flex items-center bg-white rounded shadow-md mb-4">
          <span class="px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"
              />
              <circle cx="12" cy="10" r="3" />
              <circle cx="12" cy="12" r="10" />
            </svg>
          </span>
          <input
            class="w-full h-12 focus:outline-none"
            type="text"
            placeholder="Username"
            v-model="newUser.username"
          />
        </div>
      </div>

      <div class="flex flex-col space-y-1">
        <div class="flex items-center bg-white rounded shadow-md mb-4">
          <span class="px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
              <path d="M7 11V7a5 5 0 0 1 9.9-1"></path>
            </svg>
          </span>
          <input
            class="w-full h-12 focus:outline-none"
            type="text"
            placeholder="Password"
            v-model="newUser.password"
          />
        </div>
      </div>

      <div class="flex flex-col space-y-1">
        <div class="flex items-center bg-white rounded shadow-md mb-4">
          <span class="px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path
                d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
              ></path>
              <polyline points="22,6 12,13 2,6"></polyline>
            </svg>
          </span>
          <input
            class="w-full h-12 focus:outline-none"
            type="email"
            placeholder="Email"
            v-model="newUser.email"
          />
        </div>
      </div>

      <div class="flex flex-col space-y-1">
        <div class="flex items-center bg-white rounded shadow-md mb-4">
          <span class="px-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#000000"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <circle cx="12" cy="12" r="3"></circle>
              <path
                d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"
              ></path>
            </svg>
          </span>
          <dropdownlist
            class="h-12"
            :style="{ width: '230px' }"
            :data-items="userTypes"
            :default-item="'Select a user type'"
            :value="newUser.type"
            @change="newUserTypeSelected"
          />
        </div>
      </div>
    </div>

    <div v-if="errorResponseMessage?.length > 0" class="font-bold text-red">
      Error: {{ errorResponseMessage }}
    </div>
    <dialog-actions-bar>
      <kbutton @click="toggleAddUser">Cancel</kbutton>
      <kbutton :theme-color="'primary'" @click="addNewUser">Proceed</kbutton>
    </dialog-actions-bar>
  </k-dialog>
  <div class="mt-2 h-full">
    <kbutton
      class="mt-2 mx-2 px-2"
      :theme-color="'primary'"
      @click="toggleAddUser"
      >Add user</kbutton
    >
    <kbutton
      class="mt-2 mx-2 px-2"
      :theme-color="'primary'"
      @click="exportExcel"
      >Export list</kbutton
    >
    <Grid
      class="mt-2 px-4 py-4"
      ref="grid"
      :data-items="result"
      :sortable="true"
      :sort="sort"
      :filterable="true"
      :filter="filter"
      :pageable="true"
      :skip="skip"
      :take="take"
      @rowclick="rowClick"
      @sortchange="sortChangeHandler"
      @filterchange="filterChangeHandler"
      @pagechange="pageChangeHandler"
      :columns="columns"
    >
      <template v-slot:myTemplate="{ props }">
        <div>
          <!-- <kbutton class="mt-2 mx-2 px-2" :theme-color="'primary'" @click="deleteUser(props.dataItem)">Delete</kbutton> -->
          <kbutton
            v-if="props.dataItem.isActive == true"
            class="mt-2 mx-2 px-2 w-20"
            :theme-color="'warning'"
            @click="deactivateUser(props.dataItem)"
            >Deactivate</kbutton
          >
          <kbutton
            v-if="props.dataItem.isActive == false"
            class="mt-2 mx-2 px-2 w-20"
            :theme-color="'success'"
            @click="activateUser(props.dataItem)"
            >Activate</kbutton
          >
        </div>
      </template>
    </Grid>
  </div>
</template>
<script>
import { Grid, GridToolbar } from "@progress/kendo-vue-grid";
import { Button } from "@progress/kendo-vue-buttons";
import { process } from "@progress/kendo-data-query";
import { saveExcel } from "@progress/kendo-vue-excel-export";
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { DropDownList } from "@progress/kendo-vue-dropdowns";
import { toRaw } from "vue";

import "@progress/kendo-theme-default/dist/all.css";
export default {
  components: {
    Grid: Grid,
    "grid-toolbar": GridToolbar,
    kbutton: Button,
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    dropdownlist: DropDownList,
  },
  data: function () {
    return {
      userTypes: ["NN_SECRETARY", "NN_AGENCY", "NN_SALESSUPPORT"],
      newUser: {
        fullName: null,
        username: null,
        password: null,
        email: null,
        type: null,
      },
      errorResponseMessage: null,
      addUserVisible: false,
      updatedData: [],
      editID: null,
      sort: null,
      filter: null,
      skip: 0,
      take: 20,
      columns: [
        {
          title: "Actions",
          cell: "myTemplate",
          filterable: false,
          width: "100",
        },
        { field: "id", editable: false, title: "ID", width: "300px" },
        { field: "fullName", editable: false, title: "Full Name" },
        { field: "type", editable: false, title: "User Type" },
        { field: "username", editable: false, title: "Username" },
        { field: "email", editable: false, title: "E-Mail" },
      ],
      gridData: [],
    };
  },
  mounted() {
    this.loadUsers();
  },
  computed: {
    activateUserUrl() {
      return new URL(
        this.$appConfig.API_ACTIVATE_USER_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    deactivateUserUrl() {
      return new URL(
        this.$appConfig.API_DEACTIVATE_USER_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    getUsersUrl() {
      return new URL(
        this.$appConfig.API_GET_USERS_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    addUserUrl() {
      return new URL(
        this.$appConfig.API_CREATE_USER_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    deleteUserUrl() {
      return new URL(
        this.$appConfig.API_DELETE_USER_URL,
        this.$appConfig.API_BASE_URL
      );
    },

    result: {
      get: function () {
        return process(this.gridData, {
          sort: this.sort,
          filter: this.filter,
          take: this.take,
          skip: this.skip,
        });
      },
    },
  },
  methods: {
    deactivateUser(dataItem) {
      const url = this.deactivateUserUrl.href;
      const user = toRaw(dataItem);

      this.axios
        .post(
          url,
          {
            userId: user.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$swal
            .fire("Success", `User was deactivated`, "success")
            .then(this.loadUsers());
        })
        .catch((error) => {
          console.log(error);
        });
    },

    activateUser(dataItem) {
      const url = this.activateUserUrl.href;
      const user = toRaw(dataItem);

      this.axios
        .post(
          url,
          {
            userId: user.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$swal
            .fire("Success", `User was activated`, "success")
            .then(this.loadUsers());
        })
        .catch((error) => {
          console.log(error);
        });
    },

    deleteUser(dataItem) {
      let user = toRaw(dataItem);

      // send request
      const url = this.deleteUserUrl.href;

      this.axios
        .post(
          url,
          {
            id: user.id,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.$swal
            .fire("Success", `User was deleted`, "success")
            .then(this.loadUsers());
        })
        .catch((error) => {
          console.log(error);
        });
    },

    loadUsers() {
      const url = this.getUsersUrl.href;
      this.axios
        .get(url, {
          headers: {
            Authorization: "Bearer " + this.$store.state.token,
          },
        })
        .then((response) => (this.gridData = response.data))
        .catch((error) => {
          if (error.response.status == 403)
            this.$swal
              .fire("Error", "You are not authorized for this feature", "error")
              .then(this.$router.push("/"));
        });
    },

    newUserTypeSelected(evt) {
      this.newUser.type = evt.value;
    },

    addNewUser() {
      // validate

      this.errorResponseMessage = null;
      // send request
      const url = this.addUserUrl.href;

      this.axios
        .post(
          url,
          {
            username: this.newUser.username,
            password: this.newUser.password,
            email: this.newUser.email,
            type: this.newUser.type,
            fullName: this.newUser.fullName,
          },
          {
            headers: {
              Authorization: "Bearer " + this.$store.state.token,
            },
          }
        )
        .then(() => {
          this.loadUsers();
          this.toggleAddUser();
        })
        .catch((error) => {
          if (error.response.status == 400)
            this.errorResponseMessage = error.response.data;
        });
    },

    toggleAddUser() {
      this.addUserVisible = !this.addUserVisible;
      this.newUser = {
        username: null,
        password: null,
        email: null,
        type: null,
      };
    },

    itemChange: function (e) {
      const data = this.gridData.slice();
      const index = data.findIndex((d) => d.id === e.dataItem.id);
      data[index] = { ...data[index], [e.field]: e.value };
      this.gridData = data;
      if (event.dataItem) {
        event.dataItem[e.field] = e.value;
      }
    },
    rowClick: function (e) {
      this.gridData.map((item) => (item.inEdit = false));
      this.editID = e.dataItem.id;
      e.dataItem.inEdit = true;
    },
    closeEdit(e) {
      if (e.target === e.currentTarget) {
        this.editID = null;
      }
    },
    addRecord() {
      const newRecord = { id: this.gridData.length + 1 };
      const data = this.gridData.slice();
      data.unshift(newRecord);
      this.gridData = data;
      this.editID = newRecord.id;
    },
    sortChangeHandler(event) {
      this.sort = event.sort;
    },
    filterChangeHandler(event) {
      this.filter = event.filter;
    },
    pageChangeHandler(event) {
      this.take = event.page.take;
      this.skip = event.page.skip;
    },
    exportExcel() {
      saveExcel({
        columns: this.columns,
        data: this.gridData,
        fileName: "kua-nn-portal-users",
      });
    },
  },
  inject: ["$appConfig"],
};
</script>
