<template>
<div class="flex items-center min-h-screen p-4 lg:justify-center" >
  <div class="flex flex-col overflow-hidden bg-white rounded-md shadow-lg max md:flex-row md:flex-1 lg:max-w-screen-md">
    <div class="p-4 py-6 text-white bg-red-500 md:w-80 md:flex-shrink-0 md:flex md:flex-col md:items-center md:justify-evenly">
      <div class="my-3 text-4xl font-bold tracking-wider text-center">
        <div><img src="kualogo.png"/></div>
        <!--
          <div class="flex flex-col">
          <img src="lloyds.png"/>
        </div>
        -->
      </div>
      <p class="mt-6 font-normal text-center text-gray-300 md:mt-0">
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Excepturi reprehenderit quis, incidunt voluptatibus blanditiis, quasi deserunt itaque vel dicta repellat placeat dolores culpa nemo laboriosam asperiores in voluptas hic. Pariatur?
      </p>
      <p class="mt-6 text-sm text-center text-gray-300">
        Read our <a href="#" class="underline">terms</a> and <a href="#" class="underline">conditions</a>
      </p>
    </div>
    <div class="p-5 bg-white md:flex-1">
      <h3 class="my-4 text-2xl font-semibold text-gray-700">NN Portal Agent Registration</h3>

      <div class="flex flex-col space-y-5">
        <div class="flex flex-col space-y-1">
          <div class="flex items-center bg-white rounded shadow-md mb-4">
            <span class="px-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M5.52 19c.64-2.2 1.84-3 3.22-3h6.52c1.38 0 2.58.8 3.22 3"/><circle cx="12" cy="10" r="3"/><circle cx="12" cy="12" r="10"/></svg>
            </span>
            <input class="w-full h-12 focus:outline-none" type="text" placeholder="Username" v-model="username">
          </div>
        </div>
        <div class="flex flex-col space-y-1">
          <div class="flex items-center bg-white rounded shadow-md mb-4">
            <span class="px-3">
              <!-- <svg class="fill-current text-gray-500 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4 8V6a6 6 0 1 1 12 0h-3v2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"/></svg> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg>
            </span>
            <input class="w-full h-12 focus:outline-none" type="password" placeholder="Password" v-model="password">
          </div>

        </div>

        <div class="flex flex-col space-y-1">
          <div class="flex items-center bg-white rounded shadow-md mb-4">
            <span class="px-3">
              <!-- <svg class="fill-current text-gray-500 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M4 8V6a6 6 0 1 1 12 0h-3v2h4a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2v-8c0-1.1.9-2 2-2h1zm5 6.73V17h2v-2.27a2 2 0 1 0-2 0zM7 6v2h6V6a3 3 0 0 0-6 0z"/></svg> -->
              <!-- svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect><path d="M7 11V7a5 5 0 0 1 9.9-1"></path></svg> -->
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path><polyline points="22,6 12,13 2,6"></polyline></svg>

            </span>
            <input class="w-full h-12 focus:outline-none" type="email" placeholder="Email" v-model="email">
          </div>
        </div>
        
        <div>
          <button
            class="w-full px-4 py-2 text-lg font-semibold text-white transition-colors duration-300 bg-red-500 rounded-md shadow hover:bg-red-600 focus:outline-none focus:ring-blue-200 focus:ring-4"
            @click="doLogin"
            v-bind:disabled="loginButtonDisabled">
            Register
          </button>
        </div>
        <router-link to="/login" class="text-sm text-red-600 hover:underline focus:text-red-800">Already registered?</router-link>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapActions } from 'vuex';



export default {
  name: 'LoginForm',
  inject:['$appConfig'],

  data() {
    return {
      loginButtonDisabled: false,
      waitingFor2FA: false,
      username: null,
      password: null,
      email: null,
    }
  },

  methods: {
    async doLogin() {
      if (this.canLogin == false){
        return
      }

      //console.log(`login with username ${this.email} and password ${this.password}`)

      this.loginButtonDisabled = true;

      let url = this.registerUrl.href
      
      let body = {
        username: this.username,
        password: this.password,
        email: this.email
      }


      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(body)
      };

      try {
        const response = await fetch(url, requestOptions);
        //const data = await response.json();

        switch (response.status) {
          case 401:
            this.$swal.fire('Error', 'The supplied credentials cannot be used for user registration', 'error');
            break;

          case 200:
            this.$swal.fire('Success', 'User registration completed, you can now login to the application', 'success')
            .then(
              this.$router.push('/login')
            );
            break;

          case 400:
            this.$swal.fire('Error', 'Please check your input, and try again', 'error');
            break;

          default:
            this.$swal.fire('Error', 'Something went wrong. Please try again, or contact us.', 'error');
        }
        
      } catch (error) {
        this.$swal.fire('Error', 'Something went wrong. Please try again, or contact us.', 'error');
      }
      
      this.loginButtonDisabled = false;
    },

  },

  computed: {
    canLogin () {
      return (false == (this.username == null || this.username.length == 0 || this.password == null || this.password.length == 0))
    },

    registerUrl() {
      return new URL(this.$appConfig.API_REGISTER_URL, this.$appConfig.API_BASE_URL)
    },

  },
}
</script>