<template>
  <div class="flex flex-col h-screen">
    <!-- <div ref="topbarWrapper" v-if="userLoggedIn"  class="w-FULL h-15">
        <TopBar v-bind:auto-logout-seconds="getTimeoutSeconds()"></TopBar>
      </div> -->

    <div class="flex flex-row h-full">
      <div
        ref="sidebarWrapper"
        v-if="userLoggedIn"
        class="w-60 h-full shrink-0"
      >
        <nav-sidebar
          v-bind:auto-logout-seconds="getTimeoutSeconds()"
        ></nav-sidebar>
      </div>

      <div
        ref="pageContentWrapper"
        class="flex flex-col h-full w-full overflow-x-auto"
      >
        <router-view />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import NavBar from "./components/NavBar.vue";
import NavSidebar from "./components/NavSidebar.vue";
import TopBar from "./components/TopBar";

import "tw-elements";

export default {
  name: "App",
  inject: ["$appConfig"],

  components: { NavBar, NavSidebar, TopBar },

  created() {
    window.setInterval(() => {
      this.timeoutTimerTick();
    }, 1000);

    let storedToken =
      sessionStorage.getItem("KUA_API_TOKEN") ??
      localStorage.getItem("KUA_API_TOKEN") ??
      null;

    if (storedToken !== null) {
      //try to validate token
      const modal = this.$swal.fire({
        title: "Welcome back!",
        text: "Please wait while we are trying to restore your previous session",
        style: "information",
        showCancelButton: false, // There won't be any cancel button
        showConfirmButton: false, // There won't be any confirm button
        allowOutsideClick: false,
      });

      this.loginWithStoredToken(storedToken)
        .then((data) => {
          if (data.username) {
            modal.close();
            this.logIn({
              user: data.username,
              userFullName: data.userFullName,
              userAgentCode: data.userAgentCode,
              passwordChangeRequired: data.passwordChangeRequired,
              token: storedToken,
              permaStorage: null,
              timeoutSeconds: this.$appConfig.UI_TIMEOUT_SECONDS,
            });
          }
        })
        .catch((error) => {
          modal.close();
        });
    }
  },

  methods: {
    async loginWithStoredToken(storedToken) {
      let url = this.whoamiUrl.href;

      try {
        const response = await fetch(url, {
          headers: new Headers({ Authorization: "Bearer " + storedToken }),
        });

        if (response.status === 401) {
          this.storageClearToken();
          return;
        }

        const data = await response.json();

        return data;
      } catch (error) {
        console.log(error);
      }

      return null;
    },

    ...mapActions(["logIn", "storageClearToken", "timeoutTimerTick"]),
    ...mapGetters(["getTimeoutSeconds"]),
  },

  data() {
    return {};
  },

  computed: {
    userLoggedIn() {
      let storeToken = this.$store.state.token ?? null;
      return false == (storeToken == null);
    },

    whoamiUrl() {
      return new URL(
        this.$appConfig.API_WHOAMI_URL,
        this.$appConfig.API_BASE_URL
      );
    },
  },
};
</script>
>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  text-decoration: underline;
  color: white;
}
</style>
