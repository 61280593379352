<template>
  <div>
    <nav class="px-6 py-8 bg-blue md:flex md:justify-between md:items-center">
      <div class="flex items-center justify-between w-60">
        <router-link to="/">
          <img src="/kualogo.png" class="" alt="Avatar" />
        </router-link>
        <!-- Mobile menu button -->
        <div @click="toggleNav" class="flex md:hidden">
          <button
            type="button"
            class="text-gray-100 hover:text-gray-400 focus:outline-none focus:text-gray-400"
          >
            <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
              <path
                fill-rule="evenodd"
                d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
              ></path>
            </svg>
          </button>
        </div>
      </div>

      <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
      <ul
        :class="showMenu ? 'flex' : 'hidden'"
        class="flex-col mt-8 space-y-4 md:flex md:space-y-0 md:flex-row md:items-center md:space-x-10 md:mt-0"
      >
        <router-link to="/" class="text-gray-200 hover:text-white"
          >Home</router-link
        >
        <router-link
          v-if="canAccessPortfolios"
          to="/portfolio-transfers"
          class="text-gray-200 hover:text-white"
          >Portfolio Transfers</router-link
        >
        <router-link
          v-if="canAccessReports"
          to="/reports"
          class="text-gray-200 hover:text-white"
          >Reports</router-link
        >
        <router-link
          v-if="canAccessUsers"
          to="/users"
          class="text-gray-200 hover:text-white"
          >Users</router-link
        >
        <router-link to="/useraccount" class="text-gray-200 hover:text-white">{{
          user
        }}</router-link>
        <span class="text-white"
          ><a
            href="/logout"
            class="text-gray-200 hover:text-white"
            @click.prevent="performLogOut"
            >Αποσύνδεση
            σε
            {{ new Date(autoLogout * 1000).toISOString().substr(14, 5) }}
          </a></span
        >
      </ul>
    </nav>
  </div>
</template>
<script>
import { ref } from "vue";
import { mapActions } from "vuex";
import jwt_decode from "jwt-decode";

export default {
  setup() {
    let showMenu = ref(false);
    let show = ref(false);
    const toggleNav = () => (showMenu.value = !showMenu.value);
    return { showMenu, show, toggleNav };
  },

  props: ["autoLogoutSeconds"],

  methods: {
    async performLogOut() {
      // console.log('loggingout')
      this.logOut();
      // console.log('loggeddddout')
    },
    ...mapActions(["logOut"]),
    userHasRole(roleName) {
      const rolePropertyName =
        "http://schemas.microsoft.com/ws/2008/06/identity/claims/role";
      let jwtDecode = jwt_decode(this.$store.state.token);

      let role = jwtDecode[rolePropertyName];

      return role === roleName;
    },
  },

  computed: {
    canAccessUsers() {
      return this.userHasRole("KUA_ADMIN");
    },

    canAccessPortfolios() {
      return this.userHasRole("NN_ADMIN");
    },

    canAccessReports() {
      return this.userHasRole("SIMPLE_USER") || this.userHasRole("NN_ADMIN");
    },

    user() {
      return this.$store.state.user;
    },

    autoLogout() {
      let seconds = this.autoLogoutSeconds;
      return seconds;
    },
  },

  inject: ["$appConfig"],
};
</script>
